import * as React from 'react';
import styled from 'styled-components';

export const TopArrow: React.FC = () => {
  return (
    <StyledTopArrowWrapper>
      <StyledBar />
      <StyledArrow />
      <StyledBar />
    </StyledTopArrowWrapper>
  );
};

const StyledTopArrowWrapper = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const StyledArrow = styled.div`
  overflow: hidden;
  position: relative;
  width: 140px;
  height: 55px;
  background: transparent;

  &:before {
    content: '';
    transform: rotate(-45deg);
    display: block;
    width: 60px;
    height: 200px;
    position: absolute;
    right: 50%;
    top: 36px;
    bottom: 0;
    margin: auto;
    background: ${({ theme }) => theme.colors.body};
  }

  &:after {
    content: '';
    transform: rotate(45deg);
    display: block;
    width: 60px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 36px;
    bottom: 0;
    margin: auto;
    background: ${({ theme }) => theme.colors.body};
  }
`;

const StyledBar = styled.div`
  width: calc(100% - 140px);
  height: 55px;
  background: ${({ theme }) => theme.colors.body};
`;
