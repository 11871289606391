import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import VisibilitySensor from 'react-visibility-sensor';

import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';

export const TextSection: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  const animatedProps = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: visible ? 1 : 0
    }
  });

  const visibilityChanged = (isVisible: boolean) => {
    if (!visible && isVisible === true) {
      setVisible(true);
    }
  };

  return (
    <VisibilitySensor
      onChange={visibilityChanged}
      partialVisibility={true}
      offset={{ bottom: 150 }}
    >
      <StyledTextSection style={animatedProps}>
        <div className="container">
          <Heading tag="h2" center={true}>
            Hvorfor PrivatMegleren?
          </Heading>
          <Paragraph center={true} className="ingress">
            Med våre lokalkjente eiendomsmeglere får du en skreddersydd og
            helhetlig leveranse, tilpasset enhver bolig eller fritidsbolig. Vi
            sørger for at enhver eiendom presenteres fra sin beste side, og at
            markedsføringen treffer alle potensielle kjøpere. Slik oppnår vi
            høyest mulig pris.
          </Paragraph>
          {/*           <Paragraph center={true} className="ingress">
            Vår oppgave er å oppnå resultater for deg når du er i en situasjon
            der mye står på spill. Om du skal kjøpe eller selge, hjelper vi deg
            med strategi, taktisk planlegging og gjennomføring av dine trekk.
            Med bransjeledende produkter og innovative løsninger gir vi deg et
            fortrinn i eiendomssalget.
          </Paragraph> */}
          {/*           <Paragraph center={true} className="ingress">
            Vårt mål er å gi våre kunder en opplevelse utover det som forventes,
            og å oppfylle kjøperens ønsker til et nytt hjem. Hele 9 av 10 kunder
            velger oss på anbefaling fra tidligere kunder, og er en bekreftelse
            på at våre meglere leverer en kundeopplevelse og resultater som
            overgår dine forventninger.
          </Paragraph>
          <Paragraph center={true} className="ingress">
            Vurderer du å selge eller kjøpe eiendom, hører vi gjerne fra deg.
          </Paragraph> */}
        </div>
      </StyledTextSection>
    </VisibilitySensor>
  );
};

const StyledTextSection = styled(animated.section)`
  width: 100%;
  padding: 40px 30px 80px 30px;
  float: left;

  .container {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
  }
`;
