import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { Button } from '../../../ui/button/Button';

import { useProgressiveImage } from '../../../../utils/hooks/useProgressiveImage';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';

import { Paragraph } from '../../../ui/text/Paragraph';
import { Heading } from '../../../ui/text/Heading';

import { TopArrow } from './TopArrow';
import { Container } from '../../../layout/Container';
import { useSiteContext } from '../../../../utils/hooks/useSiteContext';

export const TopSection: React.FC = () => {
  const { routeConfig } = useSiteContext();

  const { loaded } = useProgressiveImage({
    url: 'https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/overst01.jpg'
  });

  const { fadeIn } = useFadeTransition({
    slideDown: true,
    delay: 500
  });

  return (
    <StyleTopWrapper>
      <StyledTop>
        <StyledContent>
          <StyledSquare style={fadeIn}>
            <StyledSquareContent>
              <Heading tag="h1">Forvent mer</Heading>
              <Paragraph center={true} className="ingress">
                Vårt mål er å gi våre kunder en opplevelse utover det som
                forventes. Hele 9 av 10 kunder velger oss på anbefaling fra
                tidligere kunder. Dette er en bekreftelse på at våre
                eiendomsmeglere leverer resultater som overgår dine
                forventninger.{' '}
              </Paragraph>
              <Paragraph center={true} className="ingress">
                Forbered salget i god tid sammen med din{' '}
                <a
                  className="link"
                  href="https://privatmegleren.no/kontorer/inarheten?q=&utm_source=privatmegleren-forside&utm_medium=web&utm_campain=iforkant&utm_content=lokalmegler"
                >
                  lokale eiendomsmegler.
                </a>
              </Paragraph>
              <ButtonGrid>
                <ButtonGridColumn>
                  <Button
                    type="primary"
                    colorTheme="gold"
                    as="a"
                    href={`${routeConfig?.valuation.url}?utm_source=privatmegleren-forside&utm_medium=web&utm_campain=iforkant&utm_content=selge-bolig`}
                  >
                    Selge eiendom
                  </Button>
                </ButtonGridColumn>

                <ButtonGridColumn>
                  <Button
                    type="secondary"
                    colorTheme="gold"
                    as="a"
                    href={`${routeConfig?.kjopeBolig.url}&utm_source=privatmegleren-forside&utm_medium=web&utm_campain=iforkant&utm_content=kjope-bolig`}
                  >
                    Kjøpe eiendom
                  </Button>
                </ButtonGridColumn>
              </ButtonGrid>
            </StyledSquareContent>
          </StyledSquare>
        </StyledContent>
        <TopArrow />
        <StyledTopBackground
          loaded={loaded}
          opacity={0.35}
          url="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/overst01.jpg"
        />
      </StyledTop>
    </StyleTopWrapper>
  );
};

export const Top: React.FC = () => {
  return <TopSection />;
};

const ButtonGrid = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 10px;

  @media all and (max-width: 330px) {
    grid-template-columns: repeat(1, max-content);
    grid-row-gap: 10px;
  }
`;

const ButtonGridColumn = styled.div`
  display: block;
  align-self: center;

  a {
    text-align: center;
  }
`;
const TopSectionStyle = styled(animated.div)`
  background-color: black;
  padding: 8em 0;
  /*   min-height: 80vh; */
  img {
    width: 100%;
    max-width: 600px;
  }
  .ingress {
    font-family: 'Times New Roman', sans-serif;
    font-size: 23px;
    max-width: 800px;
    margin: 2em auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSquare = styled(animated.div)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
`;

const StyledSquareContent = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: max-content;
  max-width: 600px;
  max-height: max-content;
  .link {
    color: ${({ theme }) => theme.colors.coral};
    text-decoration: underline;
  }

  .button-group {
    @media all and (max-width: 800px) {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h1 {
    width: 100%;
    text-align: center;
    font-size: 3em;
    margin-bottom: 0;

    @media all and (min-width: 600px) {
      font-size: 5.5em;
    }
  }

  p {
    margin-bottom: 30px;
  }

  .marked {
    color: ${({ theme }) => theme.colors.primary};
  }
  @media all and (max-width: 800px) {
    p {
      max-width: 250px;
      font-size: 18px;
    }
  }
`;

const StyledContent = styled.div`
  padding: 3em 20px;
  width: 100%;
`;

const StyleTopWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  float: left;
  overflow: hidden;
`;

const StyledTop = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  min-height: 660px;
  height: calc(100vw * 9 / 10);
  max-height: 80vh;
  float: left;

  @media all and( min-width:600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    min-height: 550px;
    height: calc(100vw * 9 / 10);
    max-height: 80vh;
    float: left;
  }

  @media all and (max-width: 960px) {
    height: calc(100vw * 9 / 7);
  }
`;

interface IProps {
  url: string;
  position?: string;
  loaded: boolean;
  opacity?: any;
}

const StyledTopBackground = styled.div<IProps>`
  transition: opacity 0.3s ease-out;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => `url(${props.url}) no-repeat`};
  background-size: cover;
  background-position: ${(props) =>
    props.position ? props.position : 'center'};
  opacity: ${(props) =>
    props.loaded ? (props.opacity ? props.opacity : 0.2) : 0};
  z-index: -1;
`;
